import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KeyService } from '../key/key.service';
import { CLINIC_API } from '../../app.constants';

@Injectable({
  providedIn: 'root'
})


// TODO: implemente Clinic interface
export class ClinicService {

  constructor(private http: HttpClient,
              private keyService: KeyService) { }

  public getClinic (id) {
    return this.http.post(CLINIC_API, { token: this.keyService.getKey(),
                                        id });
  }
}
