import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WORKING_HOURS_API } from '../../app.constants';
import { KeyService } from '../key/key.service';

@Injectable({
  providedIn: 'root'
})
export class WorkingHoursService {

  constructor(private http: HttpClient,
              private keyService: KeyService) { }

  public update (workingHours, clinicId) {
    return this.http.post(WORKING_HOURS_API, { token: this.keyService.getKey(),
                                               clinicId,
                                               workingHours });
  }
}
