import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { KeyService } from '../../services/key/key.service';

@Component({
  selector: 'dc-hive-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnDestroy {
  public isKeySet: boolean = false;
  private subscription: Subscription;

  constructor(private keyService: KeyService) {

    this.subscription = keyService.keyChanged$.subscribe(key => {
      this.isKeySet = key;
      console.log(key);
    });

  }

  setKey(key) {
    if (key) {
      this.keyService.setKey(key);
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

}
