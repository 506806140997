import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'clinicsNameFilter'
})

@Injectable()
export class ClinicsNameFilter implements PipeTransform {
  transform(clinicsList, args: any[]): any {
    if (args) {
      let regexp = new RegExp(`${args}`, 'ig');
      return clinicsList.filter(clinic => {
        return clinic.name.search(regexp) !== -1;
      });
    } else {
      return clinicsList;
    }
  }
}
