import { Injectable } from '@angular/core';
import { Clinic } from '../../interfaces/clinic';
import { Headers, Http } from '@angular/http';
import { CLINICS_API } from '../../app.constants';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CLINICS } from './mock/clinics.mock';
import { KeyService } from '../../services/key/key.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class ClinicsService {

  constructor(private http: HttpClient,
              private keyService: KeyService) { }

  getClinics (): Observable<Clinic[]> {
    return this.http.post<Clinic[]>(CLINICS_API, {token: this.keyService.getKey()});
  }

}
