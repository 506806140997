import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkingHoursService } from '../../services/working-hours/working-hours.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'dc-hive-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.css']
})

export class WorkingHoursComponent implements OnInit {

  public apiRunning: boolean;

  private workingHours: object;

  @Input() clinic;
  @Output() clinicUpdated: EventEmitter<any> = new EventEmitter();

  constructor(private workingHoursService: WorkingHoursService,
              private toastr: ToastrService) { }

  ngOnInit() {
    if (!this.clinic.working_hours) {
      this.workingHours = {
        Monday: '',
        Tuesday: '',
        Wednesday: '',
        Thursday: '',
        Friday: '',
        Saturday: '',
        Sunday: ''
      }
    } else {
      this.workingHours = this.clinic.working_hours;
    }
  }

  public valueChanged (field) {
    this.workingHours[field.name.charAt(0).toUpperCase() + field.name.slice(1)] = field.value;
  }

  public save () {
    this.apiRunning = true;
    this.workingHoursService.update(this.workingHours, this.clinic.id)
        .subscribe(res => {
          this.clinicUpdated.emit();
          this.apiRunning = false;
          this.toastr.success('Изменения сохранены');
        }, err => {
          this.toastr.error('Уупс, что-то пошло не так, попробуй еще раз');
          this.apiRunning = false;
        });
  }

}
