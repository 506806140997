import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DAY_LABELS } from '../../app.constants';

@Component({
  selector: 'dc-hive-working-hours-dd',
  templateUrl: './working-hours-dd.component.html',
  styleUrls: ['./working-hours-dd.component.css']
})
export class WorkingHoursDdComponent implements OnInit {

  public workingHoursForm: FormGroup;
  public DAY_LABELS;

  @Input() label;
  @Input() rawValue;
  @Output() valueUpdated: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder) {
    this.DAY_LABELS = DAY_LABELS;
  }

  ngOnInit() {
    this.createForm();
  }

  createForm () {
    this.workingHoursForm = this.fb.group({
      from: this.parseFromRaw(this.rawValue),
      to: this.parseToRaw(this.rawValue),
      dayoff: false
    });

    this.workingHoursForm.valueChanges.subscribe(form => this.onFormUpdate(form));
  }

  private onFormUpdate (form) {

    if (this.workingHoursForm.get('dayoff').value) {
      this.disableField('from');
      this.disableField('to');
      this.valueUpdated.emit({value: '', name: this.label});
    } else {
      this.enableField('from');
      this.enableField('to');
      this.valueUpdated.emit({value: this.getResultStrung(form), name: this.label});
    }

  }
  private disableField(field) {
    if (!this.workingHoursForm.get(field).disabled) {
      this.workingHoursForm.get(field).disable();
    }
  }
  private enableField(field) {
    if (this.workingHoursForm.get(field).disabled) {
      this.workingHoursForm.get(field).enable();
    }
  }
  private parseToRaw (rawValue: string) {
    if (rawValue && rawValue !== '') {
      return rawValue.split(' - ')[1];
    } else {
      return '00:00';
    }
  }

  private parseFromRaw (rawValue: string) {
    if (rawValue && rawValue !== '') {
      return rawValue.split(' - ')[0];
    } else {
      return '00:00';
    }
  }

  private getResultStrung (form) {
    return `${form.from ? form.from : '00:00'} - ${form.to ? form.to : '00:00'}`;
  }

}
