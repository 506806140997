import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { NavComponent } from './components/nav/nav.component';
import { ClinicsComponent } from './components/clinics/clinics.component';
import { ClinicComponent } from './components/clinic/clinic.component';
import { KeyService } from './services/key/key.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ClinicsNameFilter } from './pipes/clinicsNameFilter/clinics.name.filter.pipe';
import { WorkingHoursComponent } from './components/working-hours/working-hours.component';
import { WorkingHoursDdComponent } from './components/working-hours-dd/working-hours-dd.component';

const appRoutes: Routes = [
  { path: 'clinics', component: ClinicsComponent },
  { path: 'clinic/:id', component: ClinicComponent },
  // { path: '',   redirectTo: '/clinics', pathMatch: 'full' },
  { path: '**', component: ClinicsComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    ClinicsComponent,
    ClinicComponent,
    SpinnerComponent,
    ClinicsNameFilter,
    WorkingHoursComponent,
    WorkingHoursDdComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
