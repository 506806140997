import { Injectable, EventEmitter, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class KeyService {
  public  dcHiveKey: string;
  private keyChangedSource = new Subject<boolean>();
  public  keyChanged$ = this.keyChangedSource.asObservable();
  private Storage = null;

  constructor() {
    if (localStorage) {
      this.Storage = localStorage;
    }
    console.log(this.getKey());
    if (this.getKey()) {
      this.keyChangedSource.next(true);
    }
  }

  getKey () {
    this.dcHiveKey = this.getKey2Storage();
    return this.dcHiveKey;
  }

  setKey (key) {
    if (key) {
      this.dcHiveKey = key;
      this.putKey2Storage(key);
      this.keyChangedSource.next(true);
      console.log(key);
      return true;
    } else {
      return false;
    }
  }

  public putKey2Storage(key: string): void {
    if (this.Storage) {
      this.Storage.setItem('dc-token', JSON.stringify(key));
    }
  }

  public getKey2Storage(): string {
    let res = null;
    if (this.Storage) {
      res = this.Storage.getItem('dc-token');
      if (res) {
        return JSON.parse(res);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
