export const CLINICS_API = '/api/get-clinics.php';
export const CLINIC_API = '/api/get-clinic.php';
export const WORKING_HOURS_API = '/api/working-hours.php';
export const DAY_LABELS = {
  monday: 'Понедельник:',
  tuesday: 'Вторник:',
  wednesday: 'Среда:',
  thursday: 'Четверг:',
  friday: 'Пятница:',
  saturday: 'Суббота:',
  sunday: 'Воскресенье:'
}
