import { Component, OnInit } from '@angular/core';
import { Clinic } from '../../interfaces/clinic';
import { ClinicsService } from '../../services/clinics/clinics.service';
import { KeyService } from '../../services/key/key.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'dc-hive-clinics',
  templateUrl: './clinics.component.html',
  styleUrls: ['./clinics.component.css']
})
export class ClinicsComponent implements OnInit {
  public clinicsList;
  public loading: boolean;
  public keySet: boolean;

  private subscription: Subscription;

  constructor(private clinicsService: ClinicsService,
              private keyService: KeyService,
              private toastr: ToastrService) {

    this.loading = false;
    this.keySet = false;

    this.subscription = keyService.keyChanged$.subscribe(key => {
      this.getClinics();
    });

  }

  ngOnInit() {
    if (this.keyService.getKey()) {
      this.getClinics();
    }
  }

  getClinics () {
    this.loading = true;
    this.clinicsService.getClinics()
        .subscribe(clinics => {
          this.clinicsList = clinics;
          this.loading = false;
          this.keySet = true;
        }, err => {
          this.toastr.error('Уупс, что-то пошло не так, попробуй еще раз');
          this.loading = false;
        });
  }

}
