import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClinicService } from '../../services/clinic/clinic.service';
import { KeyService } from '../../services/key/key.service';

@Component({
  selector: 'dc-hive-clinic',
  templateUrl: './clinic.component.html',
  styleUrls: ['./clinic.component.css']
})
export class ClinicComponent implements OnInit {
  public tab: string;
  public clinic: object; //TODO: change to interfase
  public loading: boolean;

  constructor(private route: ActivatedRoute,
              private clinicService: ClinicService,
              private keyService: KeyService) {
    this.tab = 'basic';
    this.loading = true;
  }

  ngOnInit() {
    if (this.keyService.getKey()) {
      this.getClinic();
    }
  }

  public changeTab (tab) {
    this.tab = tab;
  }

  public clinicRefresh ($event) {
    this.getClinic();
  }

  private getClinic () {
    this.loading = true;
    this.clinicService.getClinic(+this.route.snapshot.paramMap.get('id'))
        .subscribe(clinic => {
          this.loading = false;
          this.clinic = clinic;
        });
  }
}
